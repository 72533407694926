<template>
  <div id="app">
    <div class="search">
      <button @click="bulk = !bulk" class="bulk-icon" title="Переключить на пакетный ввод фраз">
        <span class="bulk-iconm"></span>
      </button>
      <div class="search-wrap">
        <form v-if="!bulk" class="search__form" v-on:submit.prevent="getDomains">
          <input v-model="word" type="text" class="search-input" placeholder="Введите фразу или слово, * заменяет окончание слова, - перед словом исключает его из результатов">
          <button class="search__button">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M10.503 15.188a4.692 4.692 0 0 1-4.69-4.686 4.692 4.692 0 0 1 4.684-4.69 4.693 4.693 0 0 1 4.69 4.686 4.693 4.693 0 0 1-4.684 4.69m10.092 3.42l-3.206-3.203a1.378 1.378 0 0 0-.908-.393A7.46 7.46 0 0 0 18 10.495 7.5 7.5 0 1 0 10.504 18a7.46 7.46 0 0 0 4.516-1.524c.019.33.14.655.394.908l3.206 3.201a1.396 1.396 0 0 0 1.976 0 1.396 1.396 0 0 0-.001-1.977" fill="#9FA6B0" fill-rule="nonzero"/></g></svg>
          </button>
        </form>
        <form v-if="bulk" class="search__form">
          <textarea v-model="words" :class="{wrong: searchtextarea}" class="search-textarea" rows="10" placeholder="Введите до 10 слов или фраз, каждую на новой строке"></textarea>
        </form>
        <div class="search_checkbox">
          <input type="checkbox" id="search_title" name="search_title" checked v-model="title">
          <label for="search_title">В тайтлах</label>
          <input type="checkbox" id="search_h1" name="search_h1" v-model="h1">
          <label for="search_h1">В заголовках</label>
          <input type="checkbox" id="search_h2" name="search_h2" v-model="firstH2">
          <label for="search_h2">В подзаголовках</label>
          <a href="/links/search/" target="_blank">В ссылках<sup>new</sup></a>
        </div>
      </div>
      <button v-if="bulk" @click="getDomainsBulk" :class="{wrong:searchtextarea}" class="btn btn-success lift btn-search">Найти</button>
    </div>
    
    <div v-show="init" class="loading-area">
      <div class="settings">
        <div class="picked">
          <input type="radio" id="today" value="today" v-model="picked">
          <label for="today">Сегодня</label><span class="picked-count" :title="`Сегодня ${today} доменов удаляется`">{{ today }}</span>
          <input type="radio" id="nextday" value="nextday" v-model="picked">
          <label for="nextday">{{nextDay}}</label><span class="picked-count" :title="`В этот день ${nextday} доменов удаляется`">{{ nextday }}</span>
          <input type="radio" id="week" value="week" v-model="picked">
          <label for="week">Неделя</label><span class="picked-count" :title="`За неделю ${week} доменов удаляется`">{{ week }}</span>
          <input type="radio" id="all" value="all" v-model="picked">
          <label for="all">35 дней</label><span class="picked-count" :title="`В ближайшие 35 дней ${all} доменов удаляется`">{{ all }}</span>
        
          <input type="radio" id="available" value="available" v-model="picked">
          <label for="available">Свободные</label><span class="picked-count available" :title="`В базе ${available} недавно дропнутых доменов`">{{ available }}</span>
        </div>

        <div class="filters" @click="filters = !filters">
          <span class="filters__text">Фильтры</span><span class="filters__caret" :class="{'asc': filters}">&#9660;</span>
        </div>
      </div>
      
      <transition name="slide">
        <form class="filters-area" v-show="filters" v-on:submit.prevent="getDomains">
          <div class="filters__item">
            <div class="filters__title" title="Имя домена или маска">Домен</div>
            <div class="filters__inputs">
              <input type="text" class="filters__input domain-filter" v-model="domainf" placeholder="Домен или маска" aria-invalid="false">
            </div>
          </div>
          <div class="filters__item">
            <div class="filters__title" title="Суточный трафик из поисковых систем до прекращения работы сайта">Трафик из ПС</div>
            <div class="filters__inputs">
              <input type="number" class="filters__input" name="trafMin" v-model="trafMin" placeholder="От" min="0" step="10" aria-invalid="false">
              <input type="number" class="filters__input" name="trafMax" v-model="trafMax" placeholder="До" min="0" step="10" aria-invalid="false">
            </div>
          </div>
          <div class="filters__item">
            <div class="filters__title" title="Количество страниц в индексе Google за сутки до даты удаления домена">Индекс Google</div>
            <div class="filters__inputs">
              <input type="number" class="filters__input" name="gindexMin" v-model="gindexMin" placeholder="От" min="0" step="1" aria-invalid="false">
              <input type="number" class="filters__input" name="gindexMax" v-model="gindexMax" placeholder="До" min="0" step="1" aria-invalid="false">
            </div>
          </div>
          <div class="filters__item">
            <div class="filters__title" title="Показатель Яндекс ИКС">ИКС</div>
            <div class="filters__inputs">
              <input type="number" class="filters__input" name="iksMin" v-model="iksMin" placeholder="От" min="0" step="10" aria-invalid="false">
              <input type="number" class="filters__input" name="iksMax" v-model="iksMax" placeholder="До" min="0" step="10" aria-invalid="false">
            </div>
          </div>
          <div class="filters__item">
            <div class="filters__title" title="Ссылки на этот домен в интернете">Ссылки</div>
            <div class="filters__inputs">
              <input type="number" class="filters__input" name="donorsMin" v-model="donorsMin" placeholder="От" min="0" step="1" aria-invalid="false">
              <input type="number" class="filters__input" name="donorsMax" v-model="donorsMax" placeholder="До" min="0" step="1" aria-invalid="false">
            </div>
          </div>
          <div class="filters__item">
            <div class="filters__title" title="Количество лет с момента последней регистрации домена">Возраст</div>
            <div class="filters__inputs">
              <input type="number" class="filters__input" name="oldMin" v-model="oldMin" placeholder="От" min="1" step="1" aria-invalid="false">
              <input type="number" class="filters__input" name="oldMax" v-model="oldMax" placeholder="До" min="1" step="1" aria-invalid="false">
            </div>
          </div>
          <input type="submit" style="display:none">
        </form>
      </transition>
      <table class="domain-table">
        <thead>
          <tr>
            <td class="domain-name thead-padding">Домен</td>
            <td class="domain-title thead-padding">Title</td>
            <td class="domain-title thead-padding">Заголовок</td>
            <td class="domain-subtitle thead-padding">Подзаголовок</td>
            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" 
            title="Трафик из поисковых систем в сутки до прекращения работы сайта" 
            class="domain-center cursorp" 
            :class="{'current-sort': sortBy === 'traf', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" 
            @click="changeSort('traf')">
              <span class="sort-title">Трафик</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort"
            title="Количество страниц в индексе Google за сутки до даты удаления домена" 
            class="domain-center cursorp" 
            :class="{'current-sort': sortBy === 'gindex', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" 
            @click="changeSort('gindex')">
              <span class="sort-title">Индекс</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" class="domain-center cursorp" :class="{'current-sort': sortBy === 'x_value', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" @click="changeSort('x_value')">
              <span class="sort-title">ИКС</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" 
            class="domain-center cursorp" :class="{'current-sort': sortBy === 'links', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" 
            @click="changeSort('links')"
            title="Ссылки на этот домен в интернете">
              <span class="sort-title">Ссылки</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
            <td v-if="picked !== 'available'" class="thead-padding" title="Текущий или последний регистратора домена">Регистратор</td>
            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" class="domain-center cursorp years" :class="{'current-sort': sortBy === 'old', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" @click="changeSort('old')">
              <span class="sort-title">Лет</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
            <!-- ставка -->
            <td v-if="picked !== 'available'" @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" 
            class="thead-padding domain-center cursorp bids-col" 
            :class="{'current-sort': sortBy === 'bid', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" 
            @click="changeSort('bid')">
              <span class="sort-title">Ставка</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>

            <td @mouseover="mouseOverSort" @mouseleave="mouseLeaveSort" class="domain-center cursorp" :class="{'current-sort': sortBy === 'delete_date', 'sort-asc': orderBy === 'asc', 'sort-desc': orderBy === 'desc'}" @click="changeSort('delete_date')">
              <span class="sort-title">{{picked !== 'available' ? 'Удаление' : 'Освободился'}}</span>
              <div class="sort-arrows-wrap">
                <div class="sort-arrows">
                  <svg class="arrows-svg caret-up"><use href="#caret-up"></use></svg>
                  <svg class="arrows-svg caret-up-fill"><use href="#caret-up-fill"></use></svg>
                  <svg class="arrows-svg caret-down"><use href="#caret-down"></use></svg>
                  <svg class="arrows-svg caret-down-fill"><use href="#caret-down-fill"></use></svg>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <TableLine
            v-for="(domain, i) in domains"
            v-bind:domain="domain"
            v-bind:picked="picked"
            v-bind:showModal="showModal"
            @domain-info="domainInfo"
            v-bind:key="i"
          ></TableLine>
        </tbody>
      </table>
      <nav class="pagination" v-if="pages > 0">
        <div class="pagination-pages">
          <ul class="pagination-buttons">
            <li v-if="page !== 1" class="pagination-button" @click="page--" title="Предыдущая страница">&laquo;</li>
            <li v-if="hasFirst()" class="pagination-button" @click="page = 1">1</li>
            <li v-if="hasFirst()" class="pagination-space">...</li>
            <li class="pagination-button" v-for="pagenum in pagesArr" :class="{current: pagenum == page}" @click="page = pagenum">{{ pagenum }}</li>
            <li v-if="hasLast()" class="pagination-space">...</li>
            <li v-if="hasLast()" class="pagination-button" @click="page = pages">{{pages}}</li>
            <li v-if="page !== pages" class="pagination-button" @click="page++" title="Следующая страница">&raquo;</li>
          </ul>
          <select class="pagination-select" v-model="page">
            <option :value="i" v-for="i in pages">{{i}}</option>
          </select>
        </div>

        <ul class="perpage">
          <li class="perpage-item" :class="{active: perpage === i}" v-for="i in pagination" @click="perpage = i" :title="`По ${i} доменов на странице`">{{i}}</li>
        </ul>
      </nav>
    </div><!-- .loading-area -->
      <Loader 
        v-bind:loader="loader"
      />
      <modal v-if="showModal" 
      @close="showModal = false"
      v-bind:showmodal="showModal"
      ></modal>
  </div>
</template>

<script>
import Loader from "@/components/Loader"
import modal from "@/components/Modal"
import TableLine from "@/components/TableLine"
export default {
  name: 'App',
  components: {
    Loader, modal, TableLine
  },
  data() {
    return {
      init: false,
      loader: true,
      domains: [],
      periods: {},
      pages: 0,
      page: 1,
      pagination: [50,100,200],
      perpage: 50,
      nextDay: 'Завтра',
      picked: '',
      range: 2,
      sortBy: 'traf',
      orderBy: 'desc',
      word: "",
      words: "",
      title: true,
      h1: true,
      firstH2: false,
      today: "",
      nextday: "",
      week: "",
      all: "",
      available: "",
      filters: false,
      domainf: "",
      trafMin: "",
      trafMax: "",
      gindexMin: "",
      gindexMax: "",
      iksMin: "",
      iksMax: "",
      donorsMin: "",
      donorsMax: "",
      oldMin: "",
      oldMax: "",
      showModal: false,
      scrollnow: 0,
      bulk: false,
      status: false,
    }
  },
  created() {
    let url = window.location.href;

    if ( url.includes('/available-domains') ) {
      this.picked = 'available';
      this.filters = true;
    }

    fetch('/domains/periods')
    .then((response) => response.json())
    .then((json) => {
      this.init = true;

      this.periods = json;
      this.nextDay = json.nextday.dayName;

      this.today = json.today.count;
      this.nextday = json.nextday.count;
      this.week = json.week.count;
      this.all = json.all.count;
      this.available = json.available.count;

      //если это не url свободных, то переключаем в зависимости от количества
      if (this.picked !== 'available') {
        if (json.today.count > 300) this.picked = 'today';
        else this.picked = 'nextday';
      }
    });
    let status = document.querySelector('.account__patron');
    if (status !== null) {
      this.bulk = true;
      this.status = true;
    }
  },
  watch: {
    perpage: function(){
      this.getDomains();
    },
    picked: function(){
      this.page = 1;
      this.getDomains();
    },
    page: function(){
      this.getDomains();
    },
    word: function(){
      if( this.word.trim() === "") {
        this.getDomains();
      }
    },
    sortBy: function(){
      this.getDomains();
    },
    orderBy: function(){
      this.getDomains();
    }
  },
  computed: {
    searchtextarea: function(){
      let wordsArr = this.words.split(/(?:\r\n|\r|\n)+/g);
      if(wordsArr.length > 10) return true;
      return false;
    },
    rangeStart: function(){
      let start = this.page - this.range;

      return (start > 0) ? start : 1;
    },
    rangeEnd: function(){
      let end = this.page + this.range;

      return (end < this.pages) ? end : this.pages;
    },
    pagesArr: function () {
      let arrPages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        arrPages.push(i);
      }
      return arrPages;
    },
    urlParams: function () {
      let params = [];

      if (this.domainf !== "") {
        params.push('domainf='+encodeURI(this.domainf));
      }
      if (this.trafMin !== "") {
        params.push('trafMin='+this.trafMin);
      }
      if (this.trafMax !== "") {
        params.push('trafMax='+this.trafMax);
      }
      if (this.gindexMin !== "") {
        params.push('gindexMin='+this.gindexMin);
      }
      if (this.gindexMax !== "") {
        params.push('gindexMax='+this.gindexMax);
      }
      if (this.iksMin !== "") {
        params.push('iksMin='+this.iksMin);
      }
      if (this.iksMax !== "") {
        params.push('iksMax='+this.iksMax);
      }
      if (this.donorsMin !== "") {
        params.push('donorsMin='+this.donorsMin);
      }
      if (this.donorsMax !== "") {
        params.push('donorsMax='+this.donorsMax);
      }
      if (this.oldMin !== "") {
        params.push('oldMin='+this.oldMin);
      }
      if (this.oldMax !== "") {
        params.push('oldMax='+this.oldMax);
      }

      return params.join('&');
    }
  },
  methods: {
    domainInfo: function(domainname){
      this.showModal = domainname;
    },
    changeSort(value){
      //если нажимаем на другой столбец, то сортировка автоматически по убыванию
      if(this.sortBy !== value) {
        this.orderBy = 'desc';
        this.page = 1;
      }
      else {
        this.orderBy === 'desc' ? this.orderBy = 'asc' : this.orderBy = 'desc';
      }
      
      this.sortBy = value;
    },
    mouseOverSort(e){
      let arrows = e.currentTarget.querySelector('.sort-arrows');
      
      arrows.classList.remove('animate__slideOutUp');
      arrows.classList.add('animate__animated', 'animate__fadeInDown');
    },
    mouseLeaveSort(e){
      let arrows = e.currentTarget.querySelector('.sort-arrows');
      
      arrows.classList.remove('animate__fadeInDown');
      arrows.classList.add('animate__slideOutUp');
    },
    getDomains(){
      this.loader = true;

      let search = '/domains/get?word='+this.word;

      //если bulk, то берем слова оттуда и шлем на другой url
      if (this.status && this.words.trim() !== "") {
        let wordsArr = this.words.split(/(?:\r\n|\r|\n)+/g);
        let words = JSON.stringify(wordsArr);
        search = '/domains/bulk?words='+words;
        this.loader = true;
      }

      let url = search+'&title='+this.title+'&h1='+this.h1+'&h2='+this.firstH2+'&period='+this.picked+'&page='+this.page+'&perpage='+this.perpage+'&sortby='+this.sortBy+'&orderby='+this.orderBy;
      if (this.urlParams !== "") url = url+'&'+this.urlParams;
      
      fetch(url)
      .then((response) => response.json())
      .then((json) => {

        if (json.error === 'limitcheck') {
          window.msgmodal = json.msg;
          let event = new Event("notice-modal");
          document.dispatchEvent(event);
          this.loader = false;
          return;
        }
        
        this.domains = json.domains;
        this.loader = false;
        
        this.pages = Math.ceil(json.count / this.perpage);
        //если сегодня удаляется 0 доменов, то одна страница
        if (this.pages === 0) this.pages = 1;
        //если текущая страница дальше, чем кол страниц после переклучения perpage
        if (this.page > this.pages) this.page = this.pages;
      })
    },
    getDomainsBulk(){
      //блокируем кнопку, если в форме более 10 строк
      if(this.searchtextarea) return;

      if( !this.status ){
        window.msgmodal = 'Пакетный поиск доступен только для патронов';
        let event = new Event("notice-modal");
        document.dispatchEvent(event);
        return;
      }

      this.getDomains();
    },
    hasFirst() {
      return this.rangeStart !== 1;
    },
    hasLast() {
      return this.rangeEnd < this.pages;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 98%;
  margin: 0 auto;
  margin-top: 60px;
}

.bulk-icon {
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 50%;
  border: 1px solid #333;
  margin-top: .45rem;
}

.bulk-iconm {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;

  position: relative;
}

.bulk-iconm::before {
    top: -5px;
}

.bulk-iconm::after {
    top: 5px;
}

.bulk-iconm::before, .bulk-iconm::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    z-index: 1;
}

.btn-search {
  display: block;
  margin: 1rem auto;
  padding: 1rem 7rem;
}

.btn-search.wrong {
  cursor: auto;
  background-color: #999;
  border-color: #999;
}

.search {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ececec;
  padding: 10px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.search-wrap {
  flex-basis: 96%;
}

.search__form {
  display: flex;
}

.search-input {
  width: 100%;
  font-size: 15px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search-textarea {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  resize: none;
}

.search-textarea.wrong {
  border-color: #ff5d5d;
}

.search__button {
  margin-top: .4rem;
  background-color: transparent;
  border: 1px solid #aaa;
  cursor: pointer;
  outline: none;

  border-top-right-radius: .8rem;
  border-bottom-right-radius: .8rem;
  transition: all .2s ease-out;
}

.search__button:hover {
  background-color: #d5d5d5;
}

.search__button svg {
  padding-top: .2rem;
}

.search_checkbox {
  display: flex;
  flex-basis: 100%;
  align-items: center;
}

.search_checkbox label, .search_checkbox a{
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: -2px;
  user-select: none;
}

.search_checkbox a {
  color: #2c3e50;
  padding-left: 5px;
  display: inline-block;
  margin-top: -4px;
  transition: all .2s ease-out;
}

.search_checkbox a:hover {
  color: #008cba;
}

.search_checkbox sup {
  color: #ff2222;
  display: inline-block;
  margin-left: 3px;
}

.settings {
  display: flex;
  justify-content: space-between;
}

.filters {
  color: #008cba;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  margin-bottom: .6rem;
}

.filters__text:hover {
  text-decoration: underline;
}

.filters__caret {
  font-size: 0.8em;
  transition: transform .2s ease;
}

.filters-area {
  display: flex;
  justify-content: flex-end;

  height: 10rem;
}

.filters__title {
  text-align: center;
  font-size: 14px;

  border: 1px solid #ddd;
}

.filters__item {
  width: 13rem;
  max-width: 13rem;
  margin-left: 3rem;
}

.filters__inputs {
  display: flex;
  justify-content: space-between;
}

.filters__input {
  display: block;
  max-width: 6rem;
  height: 2.8rem;
  padding: 2px 4px 2px 6px;
  font-size: 14px;
  line-height: 1.4;
  color: #6f6f6f;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0;
}

.domain-filter {
  max-width: 100%;
}

.picked {
  display: flex;
  align-items: baseline;
}

.picked-count {
  display: inline-block;
  padding: 0 2px;
  margin-left: 5px;
  
  font-size: .8em;
  background-color: #fffdb4;
  align-self: flex-start;
}

.picked-count.available {
  background-color: #bfff9e;
}

#available {
  margin-left: 1rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.domain-table {
  font-size: 1.2rem;
}

tr {
  height: 12px;
  transition: all .2s linear;
}

tr > td {
  border: 1px solid #ddd;
}

thead > tr > td {
  padding: 5px 0;
}

.domain-name {
  width: 200px;
  max-width: 240px;

  position: relative;
}

.domain-title {
  width: 280px;
  max-width: 300px;
}

.domain-subtitle {
  width: 200px;
  max-width: 220px;
}


.years {
  min-width: 2.4rem;
}

.thead-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.domain-center {
  text-align: center;
}

.domain-table a {
  color: #2c3e50;
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.cursorp {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.sort-title {
  display: block;
}

.sort-arrows-wrap {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  width: 100%;
  min-width: 38px;
}

.sort-arrows {
  display: none;
  background: #fff;
  text-align: center;
}

.animate__animated.sort-arrows {
  display: block;
}

.arrows-svg {
  width: 18px;
  height: 18px;
  fill: #187300;
  vertical-align: middle;
}

.caret-up-fill, .caret-down-fill {
  display: none;
}

.current-sort.sort-desc .caret-down-fill {
  display: inline;
}

.current-sort.sort-desc .caret-down {
  display: none;
}

.current-sort.sort-asc .caret-up-fill {
  display: inline;
}

.current-sort.sort-asc .caret-up {
  display: none;
}

.current-sort {
  font-weight: 600;
}

.pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.pagination-pages {
  display: flex;
  align-items: center;
}

.pagination-buttons {
  display: flex;
  padding: 0;
}

.pagination-button {
  font-size: .9em;
  margin: 0 5px;
  cursor: pointer;
  color: #286798;

  border-radius: 3px;
  border: 1px solid #eaeef4;
  padding: 0 10px;

  transition: all .2s ease-in-out;
}
.pagination-button:hover {
  border-color: #a5b6c6;
  color: #4f9ad1;
  text-decoration: underline;
}

.pagination-space {
  margin: 0 5px;
}

.pagination-button.current {
  background: #70b418;
  color: #fff;
  cursor: auto;
  font-weight: bold;
}

.perpage {
  display: flex;
}

.perpage-item {
  padding: 0 15px;
  cursor: pointer;
  color: #40a6cf;
}
.perpage-item:hover {
    background-color: #eee;
    border-radius: 3px;
}
.perpage-item.active {
  border: 1px solid #337ab7;
  border-radius: 3px;

  color: #fff;
  background-color: #337ab7;
}

.pagination-select {
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 3px 4px;
  margin-left: 10px;
  background-color: #fff;
  outline: none;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@keyframes slideOutUp {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

.animate__slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

</style>
