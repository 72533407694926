<template>
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" @click.self="modalClose">
            <div class="modal-container">

              <div class="modal-header">
                <slot name="header">
                  История изменений сайта — {{showmodal}}
                </slot>
                <button class="modal-default-button" @click.stop="modalClose">
                    <img src="/img/cancel.svg" alt="Кнопка закрыть">
                </button>
              </div>

              <div class="modal-body">
                <slot name="body">
                  <table v-if="titles.length > 0">
                    <thead>
                        <tr>
                            <th class="prl1 title">Title</th>
                            <th class="prl1 title">Заголовок</th>
                            <th class="prl1 subtitle">Подзаголовок</th>
                            <th class="prl1 ip-addr">IP-адрес сайта</th>
                            <th class="http-code center">Код ответа</th>
                            <th class="prl1 date-time center">Дата и время</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(title, index) in titles" :key="index">
                        <td class="prl1 title">{{title.title}}</td>
                        <td class="prl1 title">{{title.h1}}</td>
                        <td class="prl1 subtitle">{{title.h2}}</td>
                        <td class="prl1 ip-addr">{{title.ip}}</td>
                        <td class="http-code center">{{title.code}}</td>
                        <td class="prl1 date-time center">{{title.datetime}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <h3 v-else-if="error !== false" class="center">Извините, история изменений для сайта {{showmodal}} отсутствует</h3>
                  <div v-if="titles.length === 0 && !error" class="loader">Loading...</div>
                </slot>
              </div>

              <!-- <div class="modal-footer">
                <slot name="footer">
                  default footer
                </slot>
              </div> -->
            </div>
          </div>
        </div>
      </transition>
</template>

<script scoped>
export default {
    data() {
        return {
            titles: [],
            error: false,
            scrollnow: 0
        }
    },
    props: ['showmodal'],
    methods: {
      modalClose(e) {
        document.body.classList.remove("prevent-scroll");
        window.scrollTo(0, this.scrollnow);

        this.$emit('close');
      }
    },
    created() {
      //запрещаем скролл при открытой модалке
      this.scrollnow = window.scrollY;
      document.body.style.top = -window.scrollY+'px';
      document.body.classList.add("prevent-scroll");

      //проверяем наличие кэшированного запроса в localStorage
      if ( sessionStorage.getItem(this.showmodal) === null ){
          fetch('/domains/titles', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: `domain=${this.showmodal}&_token=${window.csrf}`
          })
          .then( (response) => response.json() )
          .then((obj) => {
              if (obj.error === "notfound") {
                this.error = true;
              }
              else if (obj.error === 'limitcheck') {
                this.$emit('close');
                window.msgmodal = obj.msg;
                let event = new Event("notice-modal");
                document.dispatchEvent(event);
              }
              else {
                this.titles = obj;
              }
              sessionStorage.setItem(this.showmodal, JSON.stringify(obj));
          })
      }
      else {
        let json = sessionStorage.getItem(this.showmodal);
        let obj = JSON.parse(json);

        if(obj.error === "notfound") this.error = true;
        else this.titles = obj;
      }

    }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  /* display: fixed;
  left: 50%;
  top: 50%; */

  width: 1366px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  overflow-x: hidden;
  overflow-y: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
tr>td {
    border: 1px solid #ddd;
    word-wrap: break-word;
    word-break: break-all;
}

.cell-height {
    max-height: 3rem;
    overflow: hidden;
}

.title {
    min-width: 25rem;
}

.subtitle {
    min-width: 20rem;
}

.http-code {
    width: 3rem;
}

.ip-addr {
    width: 16rem;
    max-width: 16rem;
}

.date-time {
    width: 7.8rem;
}

.center {
    text-align: center;
}

.prl1 {
    padding: .8rem 1rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  min-height: 75px;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.modal-default-button {
  float: right;
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.loader,
.loader:before,
.loader:after {
  background: #1eb300;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #1eb300;
  text-indent: -9999em;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

</style>