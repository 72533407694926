<template>
  <transition name="tableline">
    <tr v-if="show">
      <td class="domain-name">
        <div class="domain-item">
          <a v-if="picked !== 'available'" :href="'http://'+domain.domainname" title="Открыть в браузере" target="_blank">{{ domain.domainname }}</a>
          <a v-else href="https://active.domains" title="Зарегистрировать домен" target="_blank" class="domain-link" @click.prevent="openWindow('https://active.domains/?prodid=54049')">{{ domain.domainname }}</a>

          <a :href="'https://reestr.rublacklist.net/search/?q='+domain.domainname" target="_blank" class="rkn" v-if="(domain.rkn === '1' && domain.title == 'Доступ ограничен' && domain.h1 == 'Доступ ограничен') || domain.rkn === '2'" title="Домен полностью находится под ограничениями РКН">
            <img src="/img/rkn.ico" alt="Иконка Роскомнадзора">
          </a>
          <a :href="'https://reestr.rublacklist.net/search/?q='+domain.domainname" target="_blank" class="rkn" v-else-if="domain.rkn === '1'" title="Частичные ограничения Роскомнадзора для одной или нескольких страниц">
            <img src="/img/rkn.ico" alt="Иконка Роскомнадзора">
          </a>
        </div>
            <div class="auc-icons" v-if="picked !== 'available'">
              <button @click="clipboard" id="domain-copy"><img src="/img/copy.svg" alt="Скопировать" title="Скопировать в буфер обмена"></button>
              <a :href="'https://my.expired.ru/billmgr?startpage=backorder&startform=backorder.order.pricelist&domain=' + domain.domainname" target="_blank" title="Этот домен на аукционе Expired.ru"><img src="/img/expired-icon.ico" alt="Иконка Expired.ru"></a>
              <a v-if="rurf" :href="'https://backorder.ru/d/' + domain.domainname" title="Этот домен на аукционе Backorder.ru" target="_blank"><img src="/img/bo-icon.png" alt="Иконка BackOrder.ru"></a>
              <a :href="'https://www.reg.ru/domain/new/rereg_details?dname=' + domain.domainname" target="_blank" title="Этот домен на аукционе Reg.ru"><img src="/img/regru-icon.ico" alt="Иконка Reg.ru"></a>
              <a v-if="!rurf" :href="'https://www.nic.ru/auction/manager/backorder_domain.cgi?domain=' + domain.domainname" target="_blank" title="Этот домен на аукционе Nic.ru"><img src="/img/rucenter-icon.ico" alt="Иконка Nic.ru"></a>
            </div>

            <div class="auc-icons" v-else>
              <button @click="clipboard" id="domain-copy"><img src="/img/copy.svg" alt="Скопировать" title="Скопировать в буфер обмена"></button>
            </div>
      </td>
      <td class="domain-title" @click="$emit('domain-info', domain.domainname)" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <div class="domain-item">
          {{ domain.title }}
        </div>
      </td>
      <td class="domain-title" @click="$emit('domain-info', domain.domainname)" @mouseover="mouseOver" @mouseleave="mouseLeave"><div class="domain-item">{{ domain.h1 }}</div></td>
      <td class="domain-subtitle" @click="$emit('domain-info', domain.domainname)" @mouseover="mouseOver" @mouseleave="mouseLeave"><div class="domain-item">{{ domain.first_h2 }}</div></td>
      <!-- <td class="domain-center httpcode" @click="$emit('domain-info', domain.domainname)">{{ domain.http_code }}</td> -->
      <td class="domain-center" title="Трафик из поисковых систем в сутки до прекращения работы сайта">{{ domain.traf }}</td>
      <td @click="openWindow('https://www.google.com/search?q=site:'+domain.domainname)"
      class="domain-center cursor-pointer" title="Количество страниц в индексе Google за сутки до даты удаления домена">
      {{ domain.gindex }}
      </td>
      <td class="domain-center" title="Показатель Яндекс ИКС на момент удаления"><a :href="'https://webmaster.yandex.ru/siteinfo/?host='+domain.domainname" target="_blank">{{ domain.x_value }}</a></td>
      <td class="domain-center">{{ domain.links }}</td>
      <td v-if="picked !== 'available'"><div class="domain-item whois" @click.prevent="whoisWindow(domain.domainname)" :title="domain.registrar">{{ domain.registrar }}</div></td>
      <td @click="openWindow('https://web.archive.org/web/*/'+domain.domainname)" class="domain-center whois" title="Посмотреть историю содержимого в Web Archive">{{ domain.old }}</td>
      <td v-if="picked !== 'available'" class="domain-center bids-col" title="Ставка на аукционах освобождающихся доменов">
        <svg v-if="bidsInfo()" width="14" height="14" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>
        <div class="bids-item" v-if="bidsInfo()">
          <div v-for="(auc, i) in bidsInfo()" :key="i">
            {{auc}}
            <svg width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
              <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
            </svg>
          </div>
        </div>
      </td>
      <td class="domain-center">
        <button @click="whoisUpdate" class="check-whois" :class="{checked}" title="Актуализировать дату удаления">
          <svg class="arrows-svg" :class="{'round':loading}"><use href="#repeat"></use></svg>
        </button>
        <a @click.prevent="whoisWindow(domain.domainname)" class="whois">{{ domain.delete_date }}</a>
      </td>
    </tr>
  </transition>
</template>

<script scoped>
export default {
    data() {
      return {
          loading: false,
          checked: false,
          show: true
      }
    },
    props: ['domain', 'picked', 'showModal'],
    methods: {
      whoisUpdate(){
        this.loading = true;

      fetch('/domains/whois', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `domain=${this.domain.domainname}&_token=${window.csrf}`
      })
      .then( (response) => response.json() )
      .then((obj) => {
          if (obj.status) {
            this.loading = false;
            this.checked = true;
            return;
          }
          this.show = false;
      })
      },
      mouseOver(e){
        if (e.target.innerText.length < 44) return;
        e.currentTarget.classList.add('tooltip');
      },
      mouseLeave(e){
        e.currentTarget.classList.remove('tooltip');
      },
      bidsInfo() {
        if (this.domain.bids?.length) return this.domain.bids;

        return false;
      },
      whoisWindow(domain){
        let w = 700;
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
    
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    
        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    
        let params = `scrollbars=no,status=no,location=no,toolbar=no,menubar=no,
        width=${w},height=500,left=${left},top=200`;
    

        let url = 'https://whois7.com/?q='+domain.trim();
    
        window.open(url, 'whois', params);
      },
      openWindow(link) {
        window.open(link, '_blank')
      },
      openWindowPost(url, data) {
          var form = document.createElement('form');
          document.body.appendChild(form);
          form.target = '_blank';
          form.method = 'post';
          form.action = url;
          for (var name in data) {
              var input = document.createElement('input');
              input.type = 'hidden';
              input.name = name;
              input.value = data[name];
              form.appendChild(input);
          }
          form.submit();
          document.body.removeChild(form);
      },
      clipboard () {
        const link = this.domain.domainname;

        navigator.clipboard.writeText(link).then(function() {
          window.noticeline = 'Скопировано!';
          let event = new Event("noticeline");
          document.dispatchEvent(event);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
    },
    computed : {
      rurf() {
        if (this.domain.domainname.endsWith('.ru') || this.domain.domainname.endsWith('.рф')) {
          return true;
        }
        return false;
      }
    },
    watch: {
      domain: function(){
        this.loading = false;
        this.checked = false;
        this.show = true;
      }
    }
}
</script>

<style>

  tbody {
    line-height: 2.7rem;
  }
  tbody .domain-title, tbody .domain-subtitle, tbody .httpcode, .whois {
    cursor: pointer;
  }

  tbody tr:hover {
    background-color: #f7f8d7;
  }

  #domain-copy {
    all: unset;
    margin-right: .2rem;
  }

  #domain-copy img {
    width: 1.4rem;
    height: 1.4rem;
    cursor: alias;
  }

  .rkn {
    position: relative;
    line-height: 0;
    max-width: 15px;
    margin-left: 2px;
  }

  .auc-icons {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1000;

    background: #f7f8d7;
    padding-left: .2rem;
    opacity: 0;
    transition: all .2s ease-in-out;
  }

  .domain-name:hover .auc-icons{
    opacity: 1;
  }

  .auc-icons img {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
  }

  .whois {
    max-width: 12rem;
  }

  .whois:hover {
    text-decoration: underline;
  }

  .bids-col {
    position: relative;
    min-width: 3.2rem;
    max-width: 2.8rem;
    padding-left: 0!important;
    padding-right: 0!important;
    line-height: 0;
  }

  tbody .bids-col svg {
    fill: #747474;
  }

  .bids-item {
    position: absolute;
    width: 15rem;

    bottom: 2rem;
    right: 2.2rem;
    z-index: -1;
    opacity: 0;

    line-height: 2.5rem;
    font-size: 1.6rem;
    background: #fff;
    padding: 1.5rem 3rem;

    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    
    text-align: initial;
    transition: opacity .2s linear;
  }

  .bids-col:hover .bids-item {
    z-index: 100;
    opacity: 1;
  }

  .bids-item svg {
    fill: #00a611!important;
  }

  .check-whois {
    all: unset;
    max-height: 1.8rem;
    margin-right: .4rem;
    color: #607d8b;
    cursor: pointer;
  }

  .checked {
    color: #00a611;
  }

  .domain-item {
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .domain-link {
    margin-right: 3px;
  }

  .httpcode {
    max-width: 45px;
  }

  .tableline-leave-active {
      -webkit-animation-duration: .75s;
      animation-duration: .75s;
      -webkit-animation-duration: calc(1s*.75);
      animation-duration: calc(1s*.75);
      -webkit-animation-name: bounceOut;
      animation-name: bounceOut
  }

  .tooltip {
    position: relative;
  }

  .tooltip .domain-item {
    position: absolute;
    top: 0;
    left: 0;

    color: #fff;
    background-color: #777;
  }

  @keyframes bounceOut {
      20% {
          -webkit-transform: scale3d(.9,.9,.9);
          transform: scale3d(.9,.9,.9)
      }

      50%,55% {
          opacity: 1;
          -webkit-transform: scale3d(1.1,1.1,1.1);
          transform: scale3d(1.1,1.1,1.1)
      }

      to {
          opacity: 0;
          -webkit-transform: scale3d(.3,.3,.3);
          transform: scale3d(.3,.3,.3)
      }
  }

</style>