<template>
    <div v-show="loader" class="lds-ring"><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
    props: ['loader']
}
</script>

<style scoped>
    .lds-ring {
    display: block;
    position: fixed;
    width: 160px;
    height: 160px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    }
    .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 144px;
    height: 144px;
    margin: 8px;
    border: 14px solid #dfc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #52ad3a transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
    }
    @keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }

</style>